import './App.css';
import { FaAngleDown } from 'react-icons/fa';
import { useState } from 'react';
import Itpage from './itpage';
import Enpage from './enpage';
import PrivacyPolicy from './privacypolicy';
import TermsConditions from './termsconditions';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


function App() {



  return (
    <Router>
      <Routes>
        {/* Route for the Italian page */}
        <Route path="/" element={<Navigate to="/it" />} />
        <Route path="/it" element={<Itpage />} />
        <Route path="/en" element={<Enpage />} />
        <Route path="/pp" element={<PrivacyPolicy />} />
        <Route path="/tc" element={<TermsConditions />} />
        {/* Default route for the main page */}
        {/* <Route path="/" element={<MainPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
