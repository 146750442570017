import React from 'react';
import './App.css';
import { FaAngleDown } from 'react-icons/fa';
import { useState, useRef, useEffect } from 'react';

const Itpage = () => {

    const items = [
        {
          name: 'Landing page',
          description: `Migliora la tua presenza online con una Landing Page personalizzata! Creato per le conversioni, fai un impatto: ordina il tuo adesso!`,
          img: '/item1.png'
        },
        {
          name: 'Sito multipagina',
          description: 'Migliora il tuo marchio con un sito Web multipagina personalizzato: design senza soluzione di continuità, contenuti accattivanti.',
          img: '/item2.png'
        },
        {
          name: 'Ecommerce',
          description: 'Aumenta le tue vendite con un sito e-commerce personalizzato: acquisti senza interruzioni, transazioni sicure, massimo impatto.',
          img: '/item3.png'
        },
        {
          name: 'Web 3',
          description: 'Migliora la tua presenza online con una Landing Page personalizzata! Creato per le conversioni, fai un impatto: ordina il tuo adesso!',
          img: '/item4.png'
        },
        
        // Add more items as needed
      ];

      const productImages = [
        [
          { url: "/banners/15.png", name: "Landing Page" },
          { url: "/banners/14.png", name: "Landing Page" },
          { url: "/banners/9.png", name: "Landing Page" },
          { url: "/banners/24.png", name: "Landing Page" },
        ],
        [
          { url: "/banners/16.png", name: "Sito Web del Ristorante" },
          { url: "/banners/17.png", name: "Sito Web del Ristorante" },
          { url: "/banners/18.png", name: "Sito Web del Ristorante" },
          { url: "/banners/19.png", name: "Sito Web del Ristorante" },
          { url: "/banners/20.png", name: "Sito Web Multipagina" },
          { url: "/banners/21.png", name: "Sito Web Multipagina" },
          { url: "/banners/22.png", name: "Sito Web Multipagina" },
          { url: "/banners/25.png", name: "Sito Web Multipagina" },
        ],
        [
          { url: "/banners/4.png", name: "Ecommerce Shop" },
          { url: "/banners/5.png", name: "Ecommerce Shop" },
          { url: "/banners/23.png", name: "Ecommerce Shop" },
          { url: "/banners/27.png", name: "Ecommerce Shop" },
          { url: "/banners/28.png", name: "Ecommerce Shop" },
          { url: "/banners/29.png", name: "Ecommerce Shop" },
          { url: "/banners/30.png", name: "Ecommerce Shop" },
          { url: "/banners/31.png", name: "Ecommerce Shop" },
        ],
        [
          { url: "/banners/1.png", name: "NFT Marketplace" },
          { url: "/banners/2.png", name: "NFT Marketplace" },
          { url: "/banners/3.png", name: "NFT Marketplace" },
          { url: "/banners/4.png", name: "Web 3 Clothes Shop" },
          { url: "/banners/5.png", name: "Web 3 Clothes Shop" },
          { url: "/banners/6.png", name: "NFT Minting X Score DApp" },
          { url: "/banners/7.png", name: "NFT Minting X Score DApp" },
          { url: "/banners/8.png", name: "NFT Minting X Score DApp" },
          { url: "/banners/10.png", name: "Web 3 Online Courses Platform" },
          { url: "/banners/11.png", name: "Web 3 Online Courses Platform" },
          { url: "/banners/12.png", name: "Web 3 Online Courses Platform" },
          { url: "/banners/13.png", name: "Web 3 Online Courses Platform" },
          { url: "/banners/26.png", name: "Web 3 Online Courses Platform" },
        ],
        // Add more arrays for other products as needed
      ];
    
      const dropdowns = [
        {
          title: "Chi siamo noi?",
          content: (
            <>
              <br />
              <p />
              <br />
              Siamo una web agency dinamica e innovativa impegnata a fornire esperienze digitali eccezionali. Il nostro team è composto da professionisti appassionati con competenze diverse, uniti dall'obiettivo comune di dare vita alla tua visione.<br />
              <br />
              {/* Your content for the first dropdown */}
            </>
          ),
        },
        {
          title: "Quali servizi offriamo?",
          content: (
            <>
              <br />
              <p />
              <br />
              Siamo specializzati nella creazione di una varietà di siti Web, che vanno da pagine di destinazione accattivanti e siti Web multipagina completi a potenti piattaforme di e-commerce e progetti Web 3.0 all'avanguardia. La nostra esperienza copre l’intero spettro dello sviluppo web.<br />
              <br />
              {/* Your content for the second dropdown */}
            </>
          ),
        },
        {
          title: "Cosa ci distingue dagli altri?",
          content: (
            <>
              <br />
              <p />
              <br />
              Ciò che ci distingue è la nostra incessante ricerca dell’eccellenza. Uniamo la creatività con la competenza tecnica per produrre siti Web che non solo hanno un aspetto straordinario, ma funzionano anche senza problemi. Il nostro impegno nel stare al passo con le tendenze del settore garantisce che il tuo progetto sia sempre all’avanguardia.<br />
              <br />
              {/* Your content for the second dropdown */}
            </>
          ),
        },
        {
          title: "Qual è la nostra esperienza nel settore?",
          content: (
            <>
              <br />
              <p />
              <br />
              Con anni di esperienza nel settore dello sviluppo web, abbiamo completato con successo una vasta gamma di progetti. Il nostro portafoglio dimostra la nostra capacità di gestire varie sfide e fornire soluzioni di alta qualità. La vostra soddisfazione è la nostra misura del successo.<br />
              <br />
              {/* Your content for the second dropdown */}
            </>
          ),
        },
        {
          title: "Quali tecnologie utilizziamo?",
          content: (
            <>
              <br />
              <p />
              <br />
              Alla base delle nostre attività di sviluppo web ci sono Node.js, React e Tailwind CSS. Node.js garantisce una solida base lato server, React offre front-end dinamici e interattivi e Tailwind CSS semplifica il nostro processo di styling. Questo potente trio ci consente di creare siti Web che non solo sono visivamente accattivanti ma funzionano anche senza problemi, fornendo un'esperienza utente ottimale.<br />
              <br />
              {/* Your content for the second dropdown */}
            </>
          ),
        },
        {
          title: "Qual è il nostro impegno per la soddisfazione del cliente?",
          content: (
            <>
              <br />
              <p />
              <br />
              La vostra soddisfazione è la nostra massima priorità. Ci impegniamo a fornire non solo un prodotto ma un'esperienza. I nostri servizi di supporto e manutenzione post-lancio garantiscono che il tuo sito web rimanga in condizioni ottimali per molto tempo dopo il suo sviluppo iniziale.<br />
              <br />
              {/* Your content for the second dropdown */}
            </>
          ),
        },
        // Add more objects for additional dropdowns
      ];
    
      const [dropdownStates, setDropdownStates] = useState(dropdowns.map(() => false));
    
      const toggleVisibility = (index) => {
        setDropdownStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = !newStates[index];
          return newStates;
        });
      };
    
      const [selectedContentIndex, setSelectedContentIndex] = useState(null);
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [currentImageIndex, setCurrentImageIndex] = useState(0);

      const openModal = (index) => {
        setSelectedContentIndex(index);
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setSelectedContentIndex(null);
        setCurrentImageIndex(0);
        setIsModalOpen(false);
      };
    
      const [activeIndex, setActiveIndex] = useState(1);
    
      const handlePrev = () => {
        setCurrentImageIndex((prevIndex) => {
          if (prevIndex === 0) {
            return productImages[selectedContentIndex].length - 1;
          } else {
            return prevIndex - 1;
          }
        });
      };
      
      const handleNext = () => {
        setCurrentImageIndex((prevIndex) => {
          if (prevIndex === productImages[selectedContentIndex].length - 1) {
            return 0;
          } else {
            return prevIndex + 1;
          }
        });
      };
    
    
      function Submit(e) {
        e.preventDefault(); // Prevent default form submission
    
        const formEle = document.querySelector("form");
        const formDatab = new FormData(formEle);
        fetch(
            "https://script.google.com/macros/s/AKfycbzhjYFdNTRb79_XnEYe38mQwj2LKcVdXW7f5b7cGogHyxufuEpnoR2CN81sPLvdj6a9/exec",
            {
                method: "POST",
                body: formDatab
            }
        )
        .then((res) => res.json())
        .then((data) => {
            // console.log(data);
            // Display success message
            alert("Form submitted successfully!");
            // Clear form fields
            formEle.reset();
        })
        .catch((error) => {
            // console.log(error);
            // Display error message
            alert("An error occurred while submitting the form. Please try again later.");
        });
    }
    
    
      const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    
      const handleProductClick = (index) => {
        setSelectedProductIndex(index);
      };
    
      const [firstDivExtensions, setFirstDivExtensions] = useState([]);
      const [secondDivExtensions, setSecondDivExtensions] = useState(['modulo', 'auth', 'paga- menti', 'data- base', '3D', 'noti- ziario', 'web3', 'stock foto', 'pro- dotti', 'carr- ello']);
    
      const handleExtensionClick = (extension) => {
        if (firstDivExtensions.includes(extension)) {
          // Move the clicked extension from the first div to the second div
          setFirstDivExtensions(firstDivExtensions.filter(ext => ext !== extension));
          setSecondDivExtensions([...secondDivExtensions, extension]);
        } else {
          // Move the clicked extension from the second div to the first div
          setFirstDivExtensions([...firstDivExtensions, extension]);
          setSecondDivExtensions(secondDivExtensions.filter(ext => ext !== extension));
        }
      };
    
      const renderExtensions = (extensions) => {
        return extensions.map((extension, index) => (
          <div key={index} className='flex w-16 h-16 bg-[#D9D9D9] rounded-2xl items-end mr-1.5 my-1.5 text-sm font-jetbrainsmono justify-center text-black extension-item cursor-pointer p-2' onClick={() => handleExtensionClick(extension)}>
            {extension}
          </div>
        ));
      };

      const [isOpen, setIsOpen] = useState(false);
      const dropdownRef = useRef(null);
    
      const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };
    
      useEffect(() => {
        // Function to handle outside clicks
        const handleOutsideClick = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
          }
        };
    
        // Adding event listener when the component mounts
        document.addEventListener('click', handleOutsideClick);
    
        // Removing event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []);

      const bottomRef = useRef(null);

      const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const overviewRef = useRef(null);

      const scrollToOverview = () => {
        overviewRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const productsRef = useRef(null);

      const scrollToProducts = () => {
        productsRef.current.scrollIntoView({ behavior: 'smooth' });
      };


  return (
    <div>
   
      <div className="relative">
        {/* Responsive Image */}
        <img
          className="w-full h-auto"
          src="bg.png"  // Replace with your image source
          alt="Responsive"
        />

        {/* Text container */}
        <div className="absolute inset-0 flex items-start justify-between p-4 h-[3em] xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm xs:max-w-screen-xs mx-auto md:z-50 z-20">
       
          
            <p href="/" className="hidden md:flex items-center space-x-3 rtl:space-x-reverse">
                {/* <img src="https://Organization.com/docs/images/logo.svg" className="h-8" alt="Organization Logo" /> */}
                {/* <span className="self-center text-2xl font-semibold whitespace-nowrap  animtxt">organization</span> */}
                <div class="content ">
                  <p>Organization</p>
                  <p>Organization</p>
                  <p>Organization</p>
                </div>
            </p>
            {/* <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-100 rounded-lg md:hidden   focus:ring-2 focus:ring-gray-200 dark:text-gray-100  dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button> */}
            <div className="hidden w-full md:block md:w-auto" id="navbar-default">
              <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
           
                <li>
                  <p onClick={scrollToProducts} className="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:hover:text-gray-200 md:p-0  cursor-pointer text-white">Servizi</p>
                </li>
                <li>
                  <p  onClick={scrollToOverview} className="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:hover:text-gray-200 md:p-0  cursor-pointer text-white ">Chi siamo</p>
                </li>
               
                <li>
                  <p  onClick={scrollToBottom} className="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:hover:text-gray-200 md:p-0  cursor-pointer  text-white">Contatto</p>
                </li>
                <li>
                    {/*  */}

                    <div ref={dropdownRef} className="relative inline-block text-left">
                <button
                  type="button"
                  className="items-center py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:hover:text-gray-200 md:p-0 flex text-white"
                  onClick={toggleDropdown}
                >
                  IT
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 2" className="w-4 h-4 ml-2">
                    <path fill="#008C45" d="M0 0h1v2H0z"/>
                    <path fill="#fff" d="M1 0h1v2H1z"/>
                    <path fill="#CD212A" d="M2 0h1v2H2z"/>
                  </svg>
                </button>

                {isOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <div className="py-1" role="none">
                      <a href="/en" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        ENG
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" className="w-4 h-4 ml-1">
                          <clipPath id="a"><path d="M0 0v30h60V0z"/></clipPath>
                          <clipPath id="b"><path d="M30 15h30v15zv15H0zH0V0zV0h30z"/></clipPath>
                          <g clipPath="url(#a)">
                            <path d="M0 0v30h60V0z" fill="#012169"/>
                            <path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6"/>
                            <path d="M0 0l60 30m0-30L0 30" clipPath="url(#b)" stroke="#C8102E" strokeWidth="4"/>
                            <path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10"/>
                            <path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6"/>
                          </g>
                        </svg>
                      </a>
                      {/* <a href="#" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        DE
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3" className="w-4 h-4 ml-2">
                          <path d="M0 0h5v3H0z"/>
                          <path fill="#D00" d="M0 1h5v2H0z"/>
                          <path fill="#FFCE00" d="M0 2h5v1H0z"/>
                        </svg>
                      </a> */}
                    </div>
                  </div>
                )}
              </div>

                    {/*  */}
                </li>
              </ul>
            </div>
     
        </div>
        <div className="absolute inset-0 flex items-center justify-center p-4 mb-[30%] z-40">
          <div className="text-center text-white">
            {/* Your text goes here */}
            <div className='pb-1 flex items-center xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm xs:max-w-screen-xs text-white text-sm sm:text-md font-bold font-jetbrainsmono h-auto border-b-2 border-gray-500 justify-between'>
              <p className=''>Agenzia Web</p>
              <div className='flex items-center gap-x-4'>

                <div onClick={scrollToBottom} className='text-sm bg-blue-600 rounded-xl py-[0.3rem] px-3'>Ordine</div>
                <a href="/en" className='text-sm md:hidden block'>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" className="w-8 h-8 ml-1">
                        <clipPath id="a"><path d="M0 0v30h60V0z"/></clipPath>
                        <clipPath id="b"><path d="M30 15h30v15zv15H0zH0V0zV0h30z"/></clipPath>
                        <g clipPath="url(#a)">
                        <path d="M0 0v30h60V0z" fill="#012169"/>
                        <path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6"/>
                        <path d="M0 0l60 30m0-30L0 30" clipPath="url(#b)" stroke="#C8102E" strokeWidth="4"/>
                        <path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10"/>
                        <path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6"/>
                        </g>
                    </svg>
                </a>
              </div>
            </div>
            <img alt="Responsive" src="organization4k.png" className="text-2xl font-bold mb-4 xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm xs:max-w-screen-xs" />
          </div>
        </div>

      </div>

      <div>

      <img alt="Responsive" src='trans.png' className="object-cover -translate-y-[50%]" style={{ width: "100%", height: "100%" }}
                  />

      </div>
      <div ref={productsRef}  className=' bg-cover grid justify-center mt-[-10%]'>
          <div className=''>
            <div className='flex justify-start font-mono text-white ml-2 sm:ml-0'>Siti web:</div>
          </div>
          <div className="pl-4 grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-28 justify-between sm:mt-[5em] mt-8 overflow-x-auto">
            <div className="flex sm:contents">
              {items.map((item, index) => (
                <div key={index} className="relative bg-[#D9D9D9] w-[15rem] h-[25rem] rounded-[4em] hover:cursor-pointer outline-2 border-2 hover:border-green-300 border-double mr-4" onClick={() => openModal(index)}>
                  <div className=' flex justify-center '>
                    <div className="  w-[14rem] h-[17rem] rounded-[3.5em] transform mt-[0.5rem]">
                      <img
                        src={item.img}
                        className="object-cover rounded-[3.5em]"
                        style={{ width: "100%", height: "100%" }}
                        alt={item.name}
                      />
                    </div>
                  </div>
                  <h3 className="text-black font-museomoderno text-[1.3rem] ml-[0.5rem] mt-[.3rem] px-2">{item.name}</h3>
                  <h3 className="text-gray-500 font-museomoderno text-[.63rem] mb-2 ml-[0.5rem] mt-[0.2rem] px-2">{item.description}</h3>
                </div>
              ))}
            </div>
          </div>


        </div>
       
        {isModalOpen && (
          <div className="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-50">
            {/* Full black background */}
            <div
              className="fixed bg-black opacity-70 inset-0 z-40"
              onClick={closeModal}
            ></div>
            <div className="relative p-4 w-full max-w-3xl z-50">
              {/* Modal content */}
              <div className="relative bg-[#252525] rounded-lg shadow dark:bg-gray-700">
                <div id="gallery" className="relative w-full" data-carousel="slide">
                  {/* Carousel wrapper */}
                  {selectedContentIndex !== null && productImages[selectedContentIndex] && (
                    <div className='h-[40em] bg-[#252525] rounded-md sm:flex grid'>
                      <div className='sm:w-4/6 w-full bg-slate-300 h-full'>
                        {productImages[selectedContentIndex][currentImageIndex] && (
                          <img src={productImages[selectedContentIndex][currentImageIndex].url} alt={`Product ${selectedContentIndex + 1} image ${currentImageIndex + 1}`} className="object-cover w-full h-full" />
                        )}
                      </div>
                      <div className='sm:w-2/6 w-full bg-[#252525] h-full p-4'>
                        <div className='flex items-center w-full'>
                          <div className='rounded-full bg-[#48AAE7] p-1 w-10 h-10 flex justify-center items-center'>
                            <img src='/logo192.png' alt='logo' className='w-8 h-8'></img>
                          </div>
                          <div className='text-xs text-white ml-2'>
                            <div className='font-bold flex items-center'>
                              Organization • <p className='text-[0.6rem] text-white py-[0.1rem] px-[0.4rem] bg-[#3f8cff] rounded-xl ml-2'>follow</p>
                            </div>
                            <div>
                              Web Dev
                            </div>
                          </div>
                        </div>

                        <div className='ml-[3rem] mt-6'>
                          {productImages[selectedContentIndex][currentImageIndex] && (
                            <span className='font-mono text-sm text-white'>{productImages[selectedContentIndex][currentImageIndex].name}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Slider controls */}
                    <button
                      type="button"
                      className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                      data-carousel-prev
                      onClick={handlePrev}
                    >
                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                      <svg
                        className="w-4 h-4 text-white dark:text-gray-300 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                      </svg>
                      <span className="sr-only">Precedente</span>
                    </span>
                  </button>

                    <button
                      type="button"
                      className="absolute top-0 end-0 z-40 flex items-start justify-center h-auto p-2 cursor-pointer group focus:outline-none"
                      data-carousel-next
                      onClick={closeModal}
                    >
                      <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="w-6 h-6 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </span>
                    </button>

                    <button
                      type="button"
                      className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                      data-carousel-next
                      onClick={handleNext}
                    >
                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                      <svg
                        className="w-4 h-4 text-white dark:text-gray-300 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                      </svg>
                      <span className="sr-only">Prossimo</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        
        <div ref={overviewRef} className="md:flex grid xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm xs:max-w-screen-xs m-auto mt-[10%] justify-center">
          <div className="w-full flex md:hidden my-10 p-4">
            <img src="info.png" className="" alt="Responsive" />
          </div>
          <div  className="w-full md:w-1/2 gird justify-center p-5 md:p-0">
            
          <div>
            {dropdowns.map((dropdown, index) => (
              <div key={index} className="grid md:w-[80%] w-full ">

                <div className='my-3'>
                  <div className="grid items-center text-md cursor-pointer border-2 bg-[#D9D9D9] p-2 rounded-md" onClick={() => toggleVisibility(index)}>
                    <div className='flex'>
                      <p className='w-full text-md font-mono leading-2'>{dropdown.title}</p>
                      <span className={`ml-2 transition-transform transform text-2xl font-mono leading-1 ${dropdownStates[index] ? 'rotate-180' : ''}`}>
                        <FaAngleDown />
                      </span>
                    </div>
                    <div className={dropdownStates[index] ? 'block' : 'hidden'}>
                      <p className='font-jetbrainsmono text-sm text-[#585858]'>{dropdown.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

            
          </div>
          <div className="w-1/2 md:block hidden">
          <img src="info.png" className="" alt="Responsive"/>
          </div>
        </div>

        <div ref={bottomRef} className='flow xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm xs:max-w-screen-xs m-auto mt-[10%] bg-black rounded-3xl border-2 border-[#767676]'>
         <div className='w-full justify-center text-md text-white font-jetbrainsmono flex py-3'>
         Ordina il tuo sito web
         </div>

         <div className='md:flex grid w-full pb-14'>

          <div className='w-full flex-col justify-center md:hidden flex mb-18'>
              <div className="mx-auto grid grid-cols-1 gap-3 px-6 md:max-w-[70%] max-w-full justify-center pt-2.5 overflow-x-auto">
                <div className="flex">
                  {items.map((item, index) => (
                    <div key={index} className={`relative bg-[#D9D9D9] w-[15rem] rounded-[2.5em] border-4 hover:cursor-pointer ${selectedProductIndex === index ? 'border-blue-500' : ''} mr-3`} onClick={() => handleProductClick(index)}>
                      <div className='flex justify-center'>
                        <div className="w-[12rem] h-[12rem] rounded-[2em] transform mt-[0.2rem]">
                          <img
                            src={item.img}
                            className="object-cover rounded-[2em]"
                            style={{ width: "100%", height: "100%" }}
                            alt={item.name}
                          />
                        </div>
                      </div>
                      <h3 className="text-black font-museomoderno xs:text-sm sm:text-xs mb-2 ml-[0.5rem] mt-[.5rem] p-2">{item.name}</h3>
                    </div>
                  ))}
                </div>
              </div>

              <div className='sm:mx-auto mx-0 text-sm font-mono text-white flex justify-between w-[70%] mt-20'>
                  <p>Estensioni</p>
                  <p></p>
                
              </div>
              <div className='sm:mx-auto mx-0 text-sm font-jetbrainsmono text-white flex justify-start sm:w-[70%] w-full mt-2 border-2 rounded-3xl bg-transparent border-[#D9D9D9] items-start flex-wrap px-1.5' style={{ minHeight: "4.75rem" /* Adjust this value as needed */ }}>
          
                {renderExtensions(firstDivExtensions)}
              </div>

              <div className='sm:mx-auto mx-0 text-sm font-jetbrainsmono text-white flex justify-start sm:w-[70%] w-full mt-10 rounded-3xl bg-transparent items-start flex-wrap px-1.5'>
             
                {renderExtensions(secondDivExtensions)}
              </div>
            </div>

            <div className='md:w-2/6 w-full md:pl-6 p-4 h-full mt-14'>
              <form className='h-full' onSubmit={(e) => Submit(e)}>
                <div className="relative z-0 w-full mb-5 group border-b-2 border-white">
                    <input type="text" name="Name" id="floating_name" className="font-jetbrainsmono block py-2.5 px-0 w-full text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none bg-transparent  appearance-none       " placeholder=" " required />
                    <label htmlFor="floating_name" className="font-jetbrainsmono peer-focus:font-medium absolute text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]      ">Nome</label>
                </div>
                <div className="relative z-0 w-full mb-5 group border-b-2 border-white">
                    <input type="email" name="Email" id="floating_email" className=" font-jetbrainsmono block py-2.5 px-0 w-full text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none bg-transparent  appearance-none       " placeholder=" " required />
                    <label htmlFor="floating_email" className=" font-jetbrainsmono peer-focus:font-medium absolute text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]      ">Indirizzo e-mail</label>
                </div>
                <div className="relative z-0 w-full mb-5 group border-b-2 border-white">
                    <input type="text" name="Budget" id="budget" className=" font-jetbrainsmono block py-2.5 px-0 w-full text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none bg-transparent  appearance-none       " placeholder=" " required />
                    <label htmlFor="budget" className=" font-jetbrainsmono peer-focus:font-medium absolute text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]      ">Bilancio €</label>
                </div>
                <div className="relative z-0 w-full mb-5 group border-b-2 border-white hidden">
                    <input type="Type" name="Type" id="type" value={selectedProductIndex !== null ? selectedProductIndex + 1 : ''} readOnly className=" font-jetbrainsmono block py-2.5 px-0 w-full text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none bg-transparent  appearance-none       " placeholder=" " required />
                    <label htmlFor="type" className=" font-jetbrainsmono peer-focus:font-medium absolute text-sm text-white placeholder-white  bg-black focus:border-transparent focus:outline-none duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]      ">Tipa</label>
                </div>
                <div className="relative z-0 w-full mb-5 group border-b-2 border-white hidden">
                  <input 
                      type="Extensions" 
                      name="Extensions" 
                      id="Extensions" 
                      value={firstDivExtensions.join(', ')} // Join array elements into p string
                      readOnly 
                      className="font-jetbrainsmono block py-2.5 px-0 w-full text-sm text-white placeholder-white bg-black focus:border-transparent focus:outline-none bg-transparent appearance-none" 
                      placeholder="" 
                      required 
                  />
                  <label 
                      htmlFor="Extensions" // Use htmlFor instead of for for accessibility
                      className="font-jetbrainsmono peer-focus:font-medium absolute text-sm text-white placeholder-white bg-black focus:border-transparent focus:outline-none duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]"
                  >
                      Tipa
                  </label>
              </div>
             


                <p type="message" className="mb-5 text-xs text-white font-jetbrainsmono peer-focus:font-medium  origin-[0]      ">Condividi i tuoi pensieri:</p>
                <div className="w-full mt-4 border-b-2 border-white">
                  <textarea
                    className="block text-sm w-full h-36 px-5 py-2.5 mt-2 text-white placeholder-white  bg-black focus:border-transparent focus:outline-none"
                    name='Message'
                    placeholder=""
                  ></textarea>
                </div>
                <button className="w-full pt-3 mt-[9em] text-sm font-medium tracking-wide bg-transparent capitalize transition-colors duration-300 transform flex justify-center" name="Name" type="submit" >
                    <img src='submit.png' alt="Responsive"/>
                </button>
              </form>
            </div>

            <div className='w-4/6 flex-col justify-center md:flex hidden'>
              <div className=" mx-auto grid grid-cols-4 gap-3 max-w-[70%] md:max-w-[90%] justify-center pt-2.5">
                {items.map((item, index) => (
                  <div key={index} className={`relative bg-[#D9D9D9] w-full h-full rounded-[2.5em] border-4 hover:cursor-pointer ${selectedProductIndex === index ? ' border-blue-500' : ''}`} onClick={() => handleProductClick(index)}>
                    <div className=' flex justify-center '>
                      <div className="  w-[96%] h-[96%] rounded-[2em] transform mt-[0.2rem]">
                        <img
                          src={item.img}
                          className="object-cover rounded-[2em]"
                          style={{ width: "100%", height: "100%" }}
                          alt="Responsive"
                        />
                      </div>
                    </div>
                    <h3 className="text-black font-museomoderno text-sm md:text-xs mb-2 ml-[0.5rem] mt-[.5rem] p-2">{item.name}</h3>
                  </div>
                ))}
              </div>
              <div className='mx-auto text-sm font-jetbrainsmono text-white flex justify-between w-[70%] mt-20'>
                  <p>Estensioni</p>
                  <p></p>
                
              </div>
              {/* First div */}
              <div className='w-[70%] md:w-[90%] mx-auto text-sm font-jetbrainsmono text-white flex justify-start mt-2 border-2 rounded-3xl bg-transparent border-[#D9D9D9] items-start flex-wrap px-1.5' style={{ minHeight: "4.75rem" /* Adjust this value as needed */ }}>
                {/* Render extensions in the first div */}
                {renderExtensions(firstDivExtensions)}
              </div>

              {/* Second div */}
              <div className='w-[70%] md:w-[90%] mx-auto text-sm font-jetbrainsmono text-white flex justify-start mt-10 rounded-3xl bg-transparent items-start flex-wrap px-1.5'>
                {/* Render extensions in the second div */}
                {renderExtensions(secondDivExtensions)}
              </div>
            </div>

         </div>

        </div>

        <footer className="bg-white bottom-0 mt-[10%]">
          <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
              <div className="md:flex md:justify-between">
                <div className="mb-6 md:mb-0">
                    <p href="https://www.organization.icu/" className="flex items-center">
                        <span className="self-center text-2xl font-semibold whitespace-nowrap ">Organization</span>
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Risorse</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4">
                                <a href="https://www.organization.icu/" className="hover:underline hover:cursor-pointer">Organization</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Follow us</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                          <li className="mb-4">
                            <a href="https://www.instagram.com/organization.icu/" className="hover:underline hover:cursor-pointer">
                              Instagram
                            </a>
                          </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Legale</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                            <li className="mb-4">
                                <a href="/pp" className="hover:underline">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/tc" className="hover:underline">Terms &amp; Conditions</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 flex">© 2024 <p href="/" className="ml-2 hover:underline">Organization™</p>. All Rights Reserved.
                </span>
                
            </div>
          </div>
      </footer>

    </div>
  );
}

export default Itpage;