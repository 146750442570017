import React from 'react';
import './App.css';

const PrivacyPolicy = () => {

    return (

        <div className='bg-white justify-center items-center grid'> 

            <div className="absolute inset-0 flex items-start justify-between p-2 h-[3em] xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm xs:max-w-screen-xs mx-auto md:z-50 z-20">
                <p href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    {/* <img src="https://Organization.com/docs/images/logo.svg" className="h-8" alt="Organization Logo" /> */}
                    <a href='/' className="self-center text-2xl font-semibold whitespace-nowrap ">organization</a>
                  
                </p>
                {/* <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-100 rounded-lg md:hidden   focus:ring-2 focus:ring-gray-200 dark:text-gray-100  dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button> */}
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="font-medium flex flex-col p-2 md:p-0 mt-4 border rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
                
                    <li>
                        <a href='/' className="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:hover:text-gray-600 md:p-0  cursor-pointer text-black">Home</a>
                    </li>
                    
                    </ul>
                </div>

            </div>

            <div className='max-w-screen-sm text-justify mt-32 p-2'>
                <h1 className='text-xl justify-center font-black m-auto'> Privacy Policy </h1><br/>

                This Privacy Policy outlines how Organization.icu ("we", "our", or "us") collects, uses, discloses, and protects personal information obtained through our website. <br/>
                <br />

                1. Collection of Personal Information <br/>

                We collect the following personal information from visitors/users through the forms on our website: <br/>

                Name <br/>
                Email address <br/>
                <br />
                2. Use of Personal Information <br/>

                The personal information collected is used solely for the purpose of providing services to our users. <br/>
                <br />
                3. Sharing of Personal Information <br/>

                We do not share personal information collected through our website with third parties. <br/>
                <br />
                4. Protection of Personal Information <br/>

                All personal information collected through our website is securely stored in our Google Sheets account. <br/>
                <br />
                5. Access and Update of Personal Information <br/>

                Unfortunately, users cannot access or update their personal information directly through our website. If users wish to update their information, they can contact us at george@organization.icu and we will assist them accordingly. <br/>
                <br />
                6. Age Restriction <br/>

                There is no age restriction for users accessing our website or services. <br/>
                <br />
                7. Use of Cookies and Tracking Technologies <br/>

                We do not use cookies or tracking technologies on our website. <br/>
                <br />
                8. Additional Legal Requirements <br/>

                We are not aware of any additional legal requirements or regulations specific to our region or industry that need to be addressed in this Privacy Policy. <br/>

                Contact Us <br/>

                If you have any questions or concerns regarding this Privacy Policy, please contact us at george@organization.icu. <br/>

                This Privacy Policy was last updated on 2/25/2024. <br/>

            </div>
            <footer className="bg-white bottom-0 mt-[10%]">
          <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
              <div className="md:flex md:justify-between">
               
                <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2 w-full">
                    
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Follow us</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                          <li className="mb-4">
                            <a href="https://www.instagram.com/organization.icu/" className="hover:underline hover:cursor-pointer">
                              Instagram
                            </a>
                          </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase  flex justify-end">Legale</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium flex justify-end">
                            {/* <li className="mb-4">
                                <p href="#" className="hover:underline">Privacy Policy</p>
                            </li> */}
                            <li>
                                <a href="/tc" className="hover:underline">Terms &amp; Conditions</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 flex">© 2024 <p href="/" className="ml-2 hover:underline">Organization™</p>. All Rights Reserved.
                </span>
                
            </div>
          </div>
      </footer>
        </div>
        );
    }
    
export default PrivacyPolicy;