import React from 'react';
import './App.css';

const TermsConditions = () => {

    return (

        <div className='bg-white justify-center items-center grid'> 

            <div className="absolute inset-0 flex items-start justify-between p-2 h-[3em] xl:max-w-screen-xl md:max-w-screen-md sm:max-w-screen-sm xs:max-w-screen-xs mx-auto md:z-50 z-20">
                <p href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    {/* <img src="https://Organization.com/docs/images/logo.svg" className="h-8" alt="Organization Logo" /> */}
                    <a href='/' className="self-center text-2xl font-semibold whitespace-nowrap ">organization</a>
                  
                </p>
                {/* <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-100 rounded-lg md:hidden   focus:ring-2 focus:ring-gray-200 dark:text-gray-100  dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button> */}
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="font-medium flex flex-col p-2 md:p-0 mt-4 border rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
                
                    <li>
                        <a href='/' className="block py-2 px-3 rounded md:hover:bg-transparent md:border-0 md:hover:text-gray-600 md:p-0  cursor-pointer text-black">Home</a>
                    </li>
                    
                    </ul>
                </div>

            </div>

            <div className='max-w-screen-sm text-justify mt-32 p-2'>
                <h1 className='text-xl justify-center font-black m-auto'>Terms & Conditions</h1><br/>

                These Terms & Conditions govern the use of the services provided by Organization.icu ("we", "our", or "us") for the creation and deployment of websites. By accessing or using our services, you agree to these Terms & Conditions.<br />
                <br />
                1. Services

                Organization.icu provides website creation services to clients. This includes consultation, design, development, and deployment of websites on the vercel.com platform. Additionally, we may assist clients in purchasing domain names if needed.<br />
                <br />
                2. Process

                Users initiate the process by filling out a form on our website.<br />
                We will contact the user to discuss project details, requirements, and pricing.<br />
                Upon agreement, we will proceed with the creation and development of the website.<br />
                The completed website will be deployed on vercel.com.<br />
                Once the website is deployed and the client is satisfied, payment is due.<br />
                After payment is received, we will provide the client with all necessary code and files related to the website.<br />
                We may provide assistance to the client in purchasing a domain name, if requested.<br />
                <br />
                3. Intellectual Property<br />

                All intellectual property rights, including copyrights, trademarks, and any other rights related to the website, belong to the client upon completion and payment. We do not retain any rights to the website or its content.<br />
                <br />
                4. Disclaimers and Limitation of Liability<br />

                We make every effort to ensure the accuracy and functionality of the websites we create. However, we cannot guarantee uninterrupted access or that the website will be error-free. We are not liable for any damages or losses arising from the use of our services.<br />
                <br />
                5. Dispute Resolution<br />

                Any disputes arising from these Terms & Conditions or the use of our services shall be resolved through negotiation and mediation. If resolution cannot be reached, the dispute shall be settled in accordance with the laws of Italy.<br />
                <br />
                6. Modifications<br />

                We reserve the right to modify or update these Terms & Conditions at any time. Changes will be effective upon posting on our website. Users are responsible for regularly reviewing these Terms & Conditions.<br />

                Contact Us

                If you have any questions or concerns regarding these Terms & Conditions, please contact us at george@organization.icu.

                These Terms & Conditions were last updated on 2/25/2024.

            </div>
            <footer className="bg-white bottom-0 mt-[10%]">
          <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
              <div className="md:flex md:justify-between">
               
                <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2 justify-between w-full">
                    
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Follow us</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium">
                          <li className="mb-4">
                            <a href="https://www.instagram.com/organization.icu/" className="hover:underline hover:cursor-pointer">
                              Instagram
                            </a>
                          </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase  flex justify-end">Legale</h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-medium flex justify-end">
                            <li className="mb-4">
                                <a href="/pp" className="hover:underline">Privacy Policy</a>
                            </li>
                            {/* <li>
                                <p href="#" className="hover:underline">Terms &amp; Conditions</p>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 flex">© 2024 <p href="/" className="ml-2 hover:underline">Organization™</p>. All Rights Reserved.
                </span>
                
            </div>
          </div>
      </footer>
        </div>
        );
    }
    
export default TermsConditions;